export const PRIVACY_COOKIE_KEY = '1ClickFactoryCookiePolicy';
const PRIVACY_COOKIE_GUID = '6AE527BA-EE1A-4218-8DE1-A0D6A5C1F966';

function getCookie(name) {
    return document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
}

export function cookieExists(name) {
    return !!getCookie(name) || null;
}

export function getCookieValue(name) {
    const keyValue = getCookie(name);
    return keyValue ? decodeURI(keyValue[2]) : null;
}

export function setCookie(name, value, expirationDate) {
    document.cookie = encodeURI(name) + '=' + encodeURI(value) + '; expires=' + expirationDate + '; path=/';
}

function dispatchCookieAcceptedEvent(oldAllowedCookieTypes, allowedCookieTypes) {
    const eventData = { oldAllowedCookieTypes, allowedCookieTypes };
    let event;
    if (typeof CustomEvent === 'function') {
        event = new CustomEvent('cookiesAccepted', { detail: eventData });
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent('cookiesAccepted', false, false, eventData);
    }
    document.dispatchEvent(event);
}

export function acceptCookies(userSelectedPreferences) {
    const oldAllowedCookieTypes = getAcceptedCookieTypes();
    const date = new Date();
    date.setFullYear(date.getFullYear() + 2);

    const cookiePreference = userSelectedPreferences.map(preference => `&${preference.type}=${preference.value}`).join('');
    const cookieValue = `guid=${PRIVACY_COOKIE_GUID}${cookiePreference}`;
    setCookie(PRIVACY_COOKIE_KEY, cookieValue, date.toGMTString());

    const allowedCookieTypes = userSelectedPreferences.filter(preference => preference.value).map(preference => preference.type);
    dispatchCookieAcceptedEvent(oldAllowedCookieTypes, allowedCookieTypes);
}

export function getAcceptedCookieTypes() {
    const privacyCookie = getCookieValue(PRIVACY_COOKIE_KEY);
    if (!privacyCookie) {
        return [];
    }

    const privacyCookieValues = privacyCookie.split('&');

    if (!privacyCookieValues) {
        return [];
    }

    return privacyCookieValues.filter(v => {
        const keyValue = v.split('=');
        return OCFPrivacySettings.cookieTypeNames.some(type => type === keyValue[0]) && keyValue[1] === 'true';
    }).map(keyValue => keyValue.split('=')[0]);
}

export function privacyCookieExists() {
    const cookie = getCookieValue(PRIVACY_COOKIE_KEY);
    return cookie != null &&
        !OCFPrivacySettings.cookieTypeNames.some(type => cookie.indexOf(type) === -1) &&
        cookie.indexOf(PRIVACY_COOKIE_GUID) > -1;
}