import { cookieExists, privacyCookieExists, getAcceptedCookieTypes } from './cookies';

const NECESSARY_COOKIE_TYPE = 'necessary';
const FUNCTIONALITY_COOKIE_TYPE = 'functionality';
const PERFORMANCE_COOKIE_TYPE = 'performance';
const MARKETING_COOKIE_TYPE = 'marketing';

let cookieTypes = [];

const necessaryLocalStorageKeys = ['partnerZoneShoppingCartPurchaseInfo', 'ngStorage-partnerZoneShoppingCartPurchaseInfo', 'nav-upgrade-first-time-tour'];
const necessarySessionStorageKeys = ['OutdatedBrowser'];

function findCookieType(cookieName) {
    // some cookie names have ID in its name, which is denoted as * in Umbraco items
    const cookieType = cookieTypes.filter(type => {
        const cookies = type.split('=')[1];
        return cookies.split(',').some(cookie => cookie !== '' && cookieName.indexOf(cookie.replace('*', '')) === 0);
    });

    return cookieType.length > 0 ? cookieType[0].split('=')[0] : null;
}

function deleteStorage() {
    for (var i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (necessaryLocalStorageKeys.indexOf(key) === -1) {
            localStorage.removeItem(key);
        }
    }

    for (var j = 0; j < sessionStorage.length; j++) {
        const key = sessionStorage.key(j);

        if (necessarySessionStorageKeys.indexOf(key) === -1) {
            sessionStorage.removeItem(key);
        }
    }
}

function clearStorageAndDeleteDisallowedCookies(allowedCookieTypes) {
    deleteStorage();

    const allCookies = document.cookie.split(';');
    if (!allCookies || !allCookies.length || allCookies[0] === '') {
        return;
    }

    allCookies.forEach(cookie => {
        const cookieName = cookie.split('=')[0].replace(' ', '');
        if (allowedCookieTypes.indexOf(findCookieType(cookieName)) === -1) {
            // Try to delete cookie. It will work if it has default domain.
            document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

            if (!cookieExists(cookieName)) {
                return;
            }

            // Make sure all possible domain names are used to delete cookie.
            // Cookies might use .1clickfactory.com, www.1clickfactory.com
            // The first delete is necessary. If trying to update cookie with domain value that is equal to default, it does not work!
            var domainParts = window.location.hostname.split('.');
            while (domainParts.length > 0) {
                const domain = domainParts.join('.');
                document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain};`;

                const dotDomain = `.${domain}`;
                document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${dotDomain};`;
               
                domainParts.shift();
            }
        }
    });
}

function executeScriptIfAllowed(cookieType, fn) {
    if (getAcceptedCookieTypes().indexOf(cookieType) > -1) {
        fn();
    } else {
        document.addEventListener('cookiesAccepted', (event) => {
            const { oldAllowedCookieTypes, allowedCookieTypes } = event.detail;
            // execute script only if cookie type was not allowed before
            if (oldAllowedCookieTypes.indexOf(cookieType) === -1 && allowedCookieTypes.indexOf(cookieType) > -1) {
                fn();
            }
        });
    }
}

function executeMarketingScriptIfAllowed(fn) {
    executeScriptIfAllowed(MARKETING_COOKIE_TYPE, fn);
}

function executeFunctionalityScriptIfAllowed(fn) {
    executeScriptIfAllowed(FUNCTIONALITY_COOKIE_TYPE, fn);
}

function executePerformanceScriptIfAllowed(fn) {
    executeScriptIfAllowed(PERFORMANCE_COOKIE_TYPE, fn);
}

function initGlobalData() {
    const cookieTypeAttr = 'data-cookie-types';

    // cookie types are stored in format type1=cookie1,cookie2&type2=cookie3,cookie4
    cookieTypes = document.querySelector(`meta[${cookieTypeAttr}]`)
        .getAttribute(cookieTypeAttr)
        .split('&');

    const cookieTypeNames = cookieTypes.map(type => type.split('=')[0]);

    global.OCFPrivacySettings = {
        cookieTypeNames,
        executeMarketingScriptIfAllowed,
        executeFunctionalityScriptIfAllowed,
        executePerformanceScriptIfAllowed
    };
}

/**
 * Attaches event listener to delete disallowed cookies when allowed cookies change.
 * And reloads page if previously allowed cookies are disallowed now (this is needed for scripts to stop running).
 */
function attachCookiesAcceptedEvent()
{
    document.addEventListener('cookiesAccepted', (event) => {
        const { oldAllowedCookieTypes, allowedCookieTypes } = event.detail;
        clearStorageAndDeleteDisallowedCookies(allowedCookieTypes);
        if (oldAllowedCookieTypes.some(type => allowedCookieTypes.indexOf(type) === -1)) {
            location.reload(false);
        }
    });
}

initGlobalData();
if (!privacyCookieExists()) {
    clearStorageAndDeleteDisallowedCookies([NECESSARY_COOKIE_TYPE]);
}
attachCookiesAcceptedEvent();